<template>
  <div class="icon-button">
    <button @click="callback($event)" :disabled="disabled">
      <span v-if="buttonType === 'edit'"
        ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z"
          /></svg
      ></span>
      <span v-if="buttonType === 'view'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          />
        </svg>
      </span>
      <span v-if="buttonType === 'delete'">
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2,2 L18,2 L18,4 L2,4 L2,2 Z M8,0 L12,0 L14,2 L6,2 L8,0 Z M3,6 L17,6 L16,20 L4,20 L3,6 Z M8,8 L9,8 L9,18 L8,18 L8,8 Z M11,8 L12,8 L12,18 L11,18 L11,8 Z"
            id="Combined-Shape"
          ></path>
        </svg>
      </span>
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: "edit",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    callback: function (e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  display: flex;
  justify-content: flex-end;
  button {
    outline: 0;
    border: 1px solid #046da2;
    padding: 0px 8px 2px 8px;
    border-radius: 15px;
    transition: all 0.3s ease 0s;
    display: flex;
    align-items: center;
    justify-content: revert;
    font-weight: 600;
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
    }
    &:active {
      outline: 0;
    }
    &:hover {
      background-color: #046da2;
      color: #fff;
      svg {
        fill: #fff;
      }
    }
    svg {
      height: 1rem;
      margin-right: 3px;
      margin-top: 2px;
    }
  }
}
.actions{
  .icon-button{
    button{
      font-size: .85rem;
    }
  }
}
</style>
